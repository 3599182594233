html,
body,
#root {
    height: 100%;
    margin: 0;
}

* {
    font-family: 'Cabin', sans-serif;
}

body {
    margin: 0;
    padding: 0;
    font-family: Cabin;
    background-color: #1b1c1d;
    color: white;
    min-height: 100vh;
    min-width: 320px;  /* Минимальная ширина для мобильных устройств */
    max-width: 100vw;  /* Максимальная ширина = ширина экрана */
    display: flex;
    flex-direction: column;
}

* {
    box-sizing: inherit;
}

#root {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.App {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* min-height: 100vh; */
    min-width: 320px;
    max-width: 100vw;

    background-size: cover; /* Растягивает изображение по ширине и высоте контейнера */
    background-position: center; /* Центрирует изображение */
    background-repeat: no-repeat; /* Отключает повторение изображения */
}

.item {
    /* background-color: #f0f0f0; */
    margin: 10px;
    width: 100%;
    text-align: center;
}

.header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    width: 80%;
}

.header img {
    max-width: 150px;
    height: 50px;
}


.chart-container {
    width: 100%; /* Занимает всю доступную ширину элемента item */
    height: 300px; /* Устанавливаем нужную высоту */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333; /* Пример цвета фона */
}

#rotating-image {
    width: 120px;
    height: 120px;
    animation: rotate 0.4s linear infinite;
}

#chart-airplane {
    position: absolute;
    width: 100px;
    height: 75px;
    top: 100%;
    left: 100%;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(180deg);
    }
}

.growth-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 5em;
    font-family: Roboto;
}

.btn {
    width: 60%;
    height: 20px;
    padding: 10px;
    border-radius: 20px;
    color: white;
    margin-top: 20px;
    background-color: #e50539;;
    border: none;
    font-size: 1em;
    font-family: Roboto;
    margin-bottom: 30px;
}

.text-container {
    width: 80%;
    margin-left: 50px;
    font-size: 24px;
}

.text-left {
    text-align: left;
}

.red {
    color: #e50539;
}

.btn-disabled {
    background-color: #47454682;
    color: gray;
}

.neon-text {
    color: #e50539; /* Ярко-красный цвет текста */
    text-shadow:
    0 0 5px #ff073a,    /* Легкое свечение вокруг текста */
    0 0 10px #ff073a,   /* Среднее свечение */
    0 0 20px #ff073a;   /* Сильное свечение */
}

.neon-white-text {
    color: white; /* Ярко-красный цвет текста */
    text-shadow:
    0 0 5px #ff073a,    /* Легкое свечение вокруг текста */
    0 0 10px #ff073a,   /* Среднее свечение */
    0 0 20px #ff073a;   /* Сильное свечение */
}

.center-app {
    display: flex;
    flex-direction: column;
    justify-content: center;  /* Центрирование по вертикали */
    align-items: center;      /* Центрирование по горизонтали */
    min-height: 100vh;        /* Высота на весь экран */
    min-width: 100vw;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.center-box {
    background-color: #343434;
    border-radius: 10px;
    color: white;
    width: 90%;            /* Ширина 90% */
    height: 300px;         /* Высота 400 пикселей */
    text-align: center;
    
    /* Центрирование окна */
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;

}
